.content-center{
    display: flex;
    align-items: center;
}
.m-lr-10{
    margin-left: 10px;
    margin-right: 10px;
}
.m-tb-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.m-tb-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.m-tb-50{
    margin-top: 50px;
    margin-bottom: 50px;
}
.m-t-20{
    margin-top: 20px;
}
.p-30{
    padding: 30px;
}
.p-tb-10{
    padding-top: 10px;
    padding-bottom: 10px;
}
.p-tb-30{
    padding-top: 30px;
    padding-bottom: 30px;
}
.p-tb-50{
    padding-top: 50px;
    padding-bottom: 50px;
}

.p-lr-10{
    padding-left: 10px;
    padding-right: 10px;
}
