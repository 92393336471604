
.coming-soon-container{
    background-image: url("../../assets/images/coming-lg.png");
    width: 100%;
    height: 100%;
    min-height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 50px 0 50px 0;
    z-index: 10;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    
    .coming-soon-container{
        background-image: url("../../assets/images/coming-sm.png");
        min-height: 735px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    
    .coming-soon-container{
        background-image: url("../../assets/images/coming-sm.png");
        min-height: 735px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    
    .coming-soon-container{
        background-image: url("../../assets/images/coming-md.png");
        min-height: 470px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
   
    .coming-soon-container{
        background-image: url("../../assets/images/coming-lg.png");
        min-height: 460px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px){
    
    .coming-soon-container{
        background-image: url("../../assets/images/coming-xl.png");
        min-height: 440px;
    }
}
@media(min-width: 1400px){
    .coming-soon-container{
        min-height: 645px;
    }
}
