.featured-section {
    margin-top: 50px;
    z-index: 10;
}
.game-screen{
    background-image: url("../../assets/images/game-lg.png");
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.border-circle-icon{
    padding: 10px;
    border-radius: 16px;
    width: 50px;
    height: 50px;
    display: flex;
    text-align: center;
    border: 1px;
    border-color: #FFFFFF4D;
}
.direct-53{
    padding: 20px;
    background: linear-gradient(to top right, #EF4444 -250%, transparent 50%, #53DFF3 400%);
    backdrop-filter: blur(10px);
}


.featured-section .dropdown-content{
    display: none;
}
.featured-section .download-btn:hover .dropdown-content {display: block;}
.featured-section .download {
    background: rgb(67 67 67 / 85%);
    background-blend-mode: luminosity;
    border-radius: 18px;
    padding: 16px 24px 16px 24px;
    position: absolute;
    text-align: right;
    top: 50px;
    left: 0px;
    backdrop-filter: blur(10px);
}
.featured-section .download-item >a> img {
    padding-top: 5px;
}
.featured-section .download-item:hover {
    background-color: #7C7C7C46;
    border-radius: 8px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .game-screen{
        background-image: url("../../assets/images/game-lg.png");
        min-height: 240px;
    }
    .direct-53{
        margin-top: 50px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .game-screen{
        background-image: url("../../assets/images/game-lg.png");
        min-height: 280px;
    }
    .direct-53{
        margin-top: 50px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .game-screen{
        background-image: url("../../assets/images/game-sm.png");
        min-height: 470px
    }
   
    
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .game-screen{
        background-image: url("../../assets/images/game-md.png");
        min-height: 470px
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .game-screen{
        background-image: url("../../assets/images/game-lg.png");
        min-height: 400px
    }
}