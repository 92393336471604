.white{
    color: white;
}
.blue{
    color: #52E1F2;
}
.gray{
    color: #717171; 
}
.white-oppacity{
    color: #FFFFFF8C;
}
.red{
    color: #EF4444;
}